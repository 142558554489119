<template>
    <table class="charts-css line  show-primary-axis show-data-axes" id="my-chart">

    <tbody>
        <tr v-for="(price, index) in priceHistory" :key="index">
        <td :style="{'--start': index ===0? previousPrice: priceHistory[index-1]/maxPrice, '--size': price/maxPrice, '--color': 'orange'}"> <span class="data" :style="{ fontSize: '11px'}">$ {{(price || 0).toFixed(2)}}</span> </td>
        </tr>
    </tbody>

    </table>

</template>

<script>

export default {
    name: 'price-graph',
    props: {
        priceHistory: {
            type: Array,
            default: ()=>[0,0,0,0,0,0,0]
        },
        previousPrice: {
            type: Number,
            default: 0,
        },
        maxPrice: {
            type: Number,
            default: 1
        },
    },
}
</script>