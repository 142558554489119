<template>
<h1 id="title">🥣Cereal Clicker 🐮</h1>
  <marquee  behavior="scroll" direction="left"><span>HTML scrolling text...</span> <span>2HTML scrolling text...2</span></marquee>
  <price-graph :previous-price="previousPrice" :price-history="prices"></price-graph>

 <div id="adsgoeshere" style="display:inline-block;width:728px;height:90px;  text-align: center;" v-html="adsenseContent"></div>
  <p>Cereal Market Price {{cerealMarketPrice}}$ </p>
  <div class="ressource-bar">
    <p>Grain: {{grain}}</p>
    <p>Milk: {{milk}}</p>
    <p>Cereal Bowl: {{availableCerealBowl}} bowl(s)</p>
    <p>Money: {{money}}$</p>
  </div>
  <br/>
  <div class="row">
    <div class='column'>
      <Clicker v-if="grainClickersShowDeactivate[0]" name='Grain' :clickFunction="addGrain" :clickValue="grainClickerValue[0]" :isActive="grainClickers[0]" ></Clicker>
      <Clicker v-if="grainClickersShowDeactivate[1]" name='Grain Bunch' :clickFunction="addGrain" :clickValue="grainClickerValue[1]" :isActive="grainClickers[1]"></Clicker>
      <Clicker v-if="grainClickersShowDeactivate[2]" name='Grain Pouch' :clickFunction="addGrain" :clickValue="grainClickerValue[2]" :isActive="grainClickers[2]"></Clicker>
      <Clicker v-if="grainClickersShowDeactivate[3]" name='O-Shape' :clickFunction="addGrain" :clickValue="grainClickerValue[3]" :isActive="grainClickers[3]"></Clicker>
      <p>Next upgrade at: {{ this.nextGrainUpgrade}}</p>
    </div>
    <div class='column'>
      <Clicker v-if="milkClickersShowDeactivate[0]" name='Tiny Cow' :clickFunction="addMilk" :clickValue="milkClickerValue[0]" :isActive="milkClickers[0]" :autoClickIntervalSecond="milkAuto[0]"></Clicker>
      <Clicker v-if="milkClickersShowDeactivate[1]" name='Mini Cow' :clickFunction="addMilk" :clickValue="milkClickerValue[1]" :isActive="milkClickers[1]"></Clicker>
      <Clicker v-if="milkClickersShowDeactivate[2]" name='Small Cow' :clickFunction="addMilk" :clickValue="milkClickerValue[2]" :isActive="milkClickers[2]"></Clicker>
      <p>Next upgrade at: {{ this.nextMilkUpgrade}}</p>

    </div>
  </div>
  <button type="button" @click="grainClickerValue[0] = grainClickerValue[0] +  1">Upgrade +1 to the Grain</button>
  <button type="button" @click="milkClickerValue[0] += 0.20*milkClickerValue[0]">Upgrade +20% to the Tiny Cow</button>
  <button type="button" @click="milkAuto[0] = 10">Buy tiny milker</button>
  <p>Available Cereal bowl{{availableCerealBowl}}</p>

    <button type="button" @click="sellMaxCerealBowl">Sell All Cereal Bowl</button>

</template>

<script>
import Clicker from './components/Clicker.vue'
import PriceGraph from './components/PriceGraph'


export default {
  name: 'App',
  components: {
    Clicker,
    PriceGraph,
  },
  mounted(){
    this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML
  },
  watch: {
    milk(){
      if(this.nextMilkUnlock > 0 && this.milk >= this.nextMilkUnlock) {
        this.nextMilkUnlock = -1
        this.unlockNextMilkClicker()
      }
      if(this.milk >= this.nextMilkUpgrade){
        this.nextMilkUpgrade = this.nextMilkUpgrade*25 +this.nextMilkUpgrade
         this.nextMilkUnlock = this.nextMilkUpgrade - 25
        this.activateNextMilkClicker();
      }
    },
    grain(){
      if(this.nextUnlock > 0 && this.grain >= this.nextUnlock) {
        this.nextUnlock = -1
        this.unlockNextGrainClicker()
      }
      if(this.grain >= this.nextGrainUpgrade){
        this.nextGrainUpgrade = this.nextGrainUpgrade*15 +this.nextGrainUpgrade
         this.nextUnlock = this.nextGrainUpgrade - 25
        this.activateNextGrainClicker();
      }
    },
    availableCerealBowl(){
      if(!this.marketUpgradeInterval && this.availableCerealBowl >= 1){

        const self= this
        this.marketUpgradeInterval = setInterval(() => {
          self.cerealMarketPrice = Math.random()
          self.previousPrice = self.prices[0]
          self.prices.splice(0,1)
          self.prices.splice(self.prices.length,0, self.cerealMarketPrice)
          console.log(self.prices)
        }, 10000);
      }

    }
  },
  computed: {
    availableCerealBowl(){
      return Math.min(Math.floor(this.milk / 500), Math.floor(this.grain/55))
    }
  },

  data(){
    return {
       adsenseContent: '',
      previousPrice: 0.2,
      prices: new Array(10),
      milkAuto: [0, 0, 0, 0],
      marketUpgradeInterval: null,
      cerealMarketPrice: 0,
      nextMilkUpgrade: 10,
      nextMilkUnlock: 5,
      nextGrainUpgrade: 10,
      nextUnlock: 5,
      grain: 0,
      milk: 0,
      money: 0,
      milkClickers: [true, false, false, false],
      milkClickersShowDeactivate: [true, false, false, false],
      milkClickerValue: [0.1, 0.5, 1, 2.2, 4],
      grainClickers: [true, false, false, false],
      grainClickersShowDeactivate: [true, false, false, false],
      grainClickerValue: [1, 10, 25, 55, 75],
      moneyConversionRate: 1,
    }
  },
  methods: {
    sellMaxCerealBowl(){
      const sellAmount =  Math.min(Math.floor(this.milk / 500), Math.floor(this.grain/55))
      this.grain = this.grain - sellAmount*55
      this.milk = this.milk - sellAmount*500
      this.money = sellAmount *this.moneyConversionRate * this.cerealMarketPrice
    },

   addGrain(value){
        this.grain = this.grain + value;
    },
    addMilk(value){
        this.milk = this.milk + value;
    },
    activateNextMilkClicker(){
        for (let i = 0; i < this.milkClickers.length; i++) {
            this.milkClickers[i];
            if( this.milkClickers[i]){
                continue;
            }

             this.milkClickers[i] = true;
            break;
            
        }
    },
    unlockNextMilkClicker(){
        for (let i = 0; i < this.milkClickersShowDeactivate.length; i++) {
            this.milkClickersShowDeactivate[i];
            if( this.milkClickersShowDeactivate[i]){
                continue;
            }

             this.milkClickersShowDeactivate[i] = true;
            break;
            
        }
    },

    activateNextGrainClicker(){
        for (let i = 0; i < this.grainClickers.length; i++) {
            this.grainClickers[i];
            if( this.grainClickers[i]){
                continue;
            }

             this.grainClickers[i] = true;
            break;
            
        }
    },
    unlockNextGrainClicker(){
        for (let i = 0; i < this.grainClickersShowDeactivate.length; i++) {
            this.grainClickersShowDeactivate[i];
            if( this.grainClickersShowDeactivate[i]){
                continue;
            }

             this.grainClickersShowDeactivate[i] = true;
            break;
            
        }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  background-image: url('./assets/bg2.jpg');
}

#my-chart.line {
  height: 200px;
  max-width: 90%;
  margin: 0 auto;
}
#app {
  font-family: 'Original Surfer', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;

}
#title {
animation: color-change 10s infinite;
}
@keyframes color-change {
  0% { color: fuchsia; }
  50% { color: black; }
  100% { color: fuchsia; }
}
marquee{
  border: 2px solid black;
  border-left: 0px;
  border-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.ressource-bar{
  display: flex;
  flex-direction: row;
  flex-grow: auto;
  justify-content: space-between;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}


</style>
