<template>
    <div><span>{{name}}:   +{{clickValue}}(+{{autoValue}}/sec)</span><button type="button" @click="click" :disabled="!isActive">Click</button></div>
</template>
<script>


export default {
    name: 'clicker',
    props: {
        name: {
            type: String,
        },
        clickFunction: {
            type: Function,
            default: () => {}
        },
        clickValue: {
            type: Number,
            default: 0
        },
        isActive: {
            type: Boolean
        },
        autoClickIntervalSecond: {
            type:Number,
            default: -1,
        }
    },
    computed: {
        autoValue(){
            return this.autoClickIntervalSecond > 1 ? this.autoClickIntervalSecond/1000 * this.clickValue : 0
        }
    },
    watch: {
        autoClickIntervalSecond(){
            if(!this.autoInterval && this.autoClickIntervalSecond > 1){
                this.autoRatio = this.autoClickIntervalSecond/1000
                const self = this
                this.autoInterval = setInterval(() => {
                    self.autoClick()
                }, 1000);
            }
        }
    },
    date() {
        return {
            cv: 1,
            name: this.name,
            autoInterval :null,
            autoRatio: 1,
        }
    },
    methods: {
        autoClick(){
            this.clickFunction(this.autoRatio * this.clickValue)
        },
        click(){
            this.clickFunction(this.clickValue)
        }
    }
}


</script>